import { Button, Skeleton } from "@nextui-org/react";

import { Checkbox } from "@nextui-org/react";
import { cn } from "@nextui-org/react";
import { Product } from "../MainPage";



const ProductSelection = ({ products, selectedProducts, handleProductButtonClick, isLoadingProducts, isLoadingVideos, isLoadingTable, isLoading, compareProducts }: { products: Product[], selectedProducts: string[], handleProductButtonClick: (product: string) => void, isLoadingProducts: boolean, isLoadingVideos: boolean, isLoadingTable: boolean, isLoading: boolean, compareProducts: (products: string) => void }) => {
    return (
        <>
            {isLoadingProducts && (
                <div className="max-w-3xl mx-auto px-3 mt-6">
                    <h2 className="text-center text-gray-700 font-bold text-lg">Searching YouTube for popular products...</h2>
                    <div className="max-w-3xl mx-auto px-3 grid sm:grid-cols-2 gap-1">
                        {[1, 2, 3, 4, 5, 6].map((index: number) => (
                            <Checkbox
                                isDisabled
                                key={index}
                                aria-label="something"
                                classNames={{
                                    base: cn(
                                        "inline-flex max-w-md w-full bg-content1 m-0",
                                        "hover:bg-content2 items-center justify-start",
                                        "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                        "data-[selected=true]:border-primary"
                                    ),
                                    label: "w-full",
                                }}
                            >
                                <div className="w-full flex justify-between">
                                    <div className="flex flex-col">
                                        <Skeleton className="rounded-lg h-[1rem] w-full mb-1">
                                            <span>a very long product name</span>
                                        </Skeleton>
                                        <Skeleton className="rounded-lg h-[0.8rem] w-1/2 bg-gray-100">
                                            <span>short</span>
                                        </Skeleton>
                                    </div>
                                </div>
                            </Checkbox>
                        ))}
                    </div>
                </div>
            )}

            {products && products.length > 0 && (
                <div
                    className={`transition-opacity duration-1000 ease-in-out ${products.length > 0 ? "opacity-100" : "opacity-0"
                        }`}
                >
                    <div className="py-6"></div>
                    <p className="text-xl font-semibold text-center mb-4">
                        Select up to{" "}
                        <span className="underline decoration-4 decoration-primary/50 font-bold">
                            3
                        </span>{" "}
                        products:
                    </p>

                    {/* PRODUCT OPTIONS */}
                    <div className="max-w-3xl mx-auto px-3 grid sm:grid-cols-2 gap-1">
                        {products.map((product: Product, index: number) => (
                            <Checkbox
                                key={index}
                                aria-label={product.name}
                                isDisabled={(selectedProducts.length > 2 && !selectedProducts.includes(product.name)) || isLoadingVideos || isLoadingTable || isLoadingProducts}
                                onClick={() => handleProductButtonClick(product.name)}
                                classNames={{
                                    base: cn(
                                        "inline-flex max-w-md w-full bg-content1 m-0",
                                        "hover:bg-content2 items-center justify-start",
                                        "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                        "data-[selected=true]:border-primary"
                                    ),
                                    label: "w-full",
                                }}
                            >
                                <div className="w-full flex justify-between">
                                    <div className="flex flex-col">
                                        <span className="text-sm sm:text-base font-semibold">{product.name}</span>
                                        <span className="text-xs sm:text-sm text-default-500">{product.description}</span>
                                    </div>
                                </div>
                            </Checkbox>
                        ))}
                    </div>

                    {selectedProducts.length > 0 && (
                        <div
                            className={`flex justify-center my-4 transition-opacity duration-1000 ease-in-out ${selectedProducts.length > 0 ? "opacity-100" : "opacity-0"
                                }`}
                        >
                            <Button
                                // className="p-2 rounded-md text-sm text-white transition-colors duration-200 bg-blue-500 hover:bg-blue-600"
                                className="font-semibold uppercase"
                                color="primary"
                                size="lg"
                                isLoading={isLoading}
                                onClick={() =>
                                    compareProducts(
                                        selectedProducts.reduce(
                                            (acc, curr) => `${acc} vs ${curr}`,
                                            ""
                                        )
                                    )
                                }
                            >
                                Compare
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default ProductSelection;

