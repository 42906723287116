import { Link } from "@nextui-org/react";
import { Skeleton } from "@nextui-org/react";
import { parseVideoLength } from "../utils";
import { useEffect, useState } from "react";
import { VideoData } from "../MainPage";



const VideoGallery = ({ isLoadingVideos, videoData }: { isLoadingVideos: boolean, videoData: VideoData[] }) => {
    const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(null);

    return (
        <div className="max-w-3xl w-full overflow-x-auto pb-4 mx-auto">
            {isLoadingVideos && (
                <div className="max-w-3xl px-3 py-1 mx-auto">
                    <h2
                        className="font-bold text-gray-700 text-lg"
                    >
                        Analyzing YouTube videos...
                    </h2>
                </div>

            )}
            {isLoadingVideos && (
                <div className="flex space-x-4 p-3 min-w-max">
                    {[...Array(6)].map((_, index) => (
                        <div key={index} className="flex flex-col w-[150px] h-[175px] rounded-lg bg-gray-50 overflow-hidden shadow-md">
                            <Skeleton className="w-full h-[75px] rounded-t-lg" />

                            <div className="p-2 flex-grow">
                                <Skeleton className="w-full h-3 mb-2 rounded-lg" />
                                <Skeleton className="w-2/3 h-3 rounded-lg" />
                            </div>

                            <div className="py-1"></div>

                            <div className="p-2 mt-auto">
                                <div className="flex justify-between items-center">
                                    <Skeleton className="w-1/3 h-3 rounded-lg" />
                                    <Skeleton className="w-1/4 h-3 rounded-lg" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!isLoadingVideos && videoData.length > 0 && (
                <div className="max-w-3xl px-3 py-1 mx-auto">
                    <h2
                        className="font-bold text-gray-700 text-lg"
                    >
                        Analyzed YouTube Videos
                    </h2>
                </div>
            )}

            <div
                className={`flex space-x-4 p-3 min-w-max transition-opacity duration-1000 ease-in-out ${videoData.length > 0 ? "visible" : "invisible"
                    }`}
            >

                {videoData.map((video: VideoData, index: number) => (
                    <div
                        key={video.video_title}
                        onClick={() =>
                            setSelectedVideoIndex(
                                index === selectedVideoIndex ? null : index
                            )
                        }
                        className="flex flex-col max-w-[150px] h-[175px] rounded-md bg-gray-50 overflow-hidden shadow-md cursor-pointer"
                    >
                        <img
                            className="w-full object-cover h-[75px]"
                            src={video.video_thumbnail}
                            alt={`Thumbnail for ${video.video_title}`}
                        />
                        <div className="p-1">
                            <p className="text-gray-700 font-semibold text-xs">{video.video_title}</p>
                        </div>
                        <div className="p-1 mt-auto">
                            <div className="flex justify-between items-center">
                                <Link
                                    className="text-xs"
                                    size="sm"
                                    href={videoData[index].link}
                                    target="_blank"
                                    showAnchorIcon
                                    rel="noopener noreferrer"
                                    style={{ pointerEvents: "auto", userSelect: "none" }}
                                    onClick={(e) => e.stopPropagation()} // Prevent triggering the parent onClick
                                >
                                    YouTube
                                </Link>
                                <p className="text-xs text-gray-500">
                                    {parseVideoLength(video.length)}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default VideoGallery;