import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NextUIProvider } from "@nextui-org/system";
import MainPage from './MainPage';

const App = () => {
  return (
    <NextUIProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<MainPage />} />
          </Routes>
        </div>
      </Router>
    </NextUIProvider>
  );
};

export default App;