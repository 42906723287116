import React from 'react';

const Navbar = () => {
  
  return (
    <nav className="p-2 flex items-center justify-center mx-auto">
        <a href="/">
          <span className="text-base sm:text-3xl font-semibold">
            wizefind
          </span>
        </a>
        <span className="ml-2 border border-zinc-700 rounded-lg px-1 text-tiny">
          beta
        </span>
    </nav>
  );
};

export default Navbar;