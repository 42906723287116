import { ProductTableData } from "../MainPage";
import { Skeleton } from "@nextui-org/skeleton";
import { Product } from "../MainPage";
import JsonToTable from "./Table";


const ProductComparisonTable = ({ isLoadingTable, comparedProducts, markdownContent }: { isLoadingTable: boolean, comparedProducts: Product[], markdownContent: ProductTableData | null }) => {
    return (
        <>
            {isLoadingTable && (
                <div className="max-w-6xl mx-auto">
                    <div className="py-4"></div>
                    {/* <h1 className="max-w-3xl mx-auto text-center sm:text-left py-4 px-3 text-xl font-bold text-gray-700">Loading comparison table...</h1> */}
                    <table className="w-full max-w-6xl mx-auto">
                        <thead>
                            <tr className="border-b-2">
                                {comparedProducts.map((product: Product, index: number) => (
                                    <th
                                        key={index}
                                        className="p-2 font-bold text-lg"
                                        style={{ width: `${100 / comparedProducts.length}%` }}
                                    >
                                        {product.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(6)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                    {comparedProducts.map((_, colIndex) => (
                                        <td
                                            key={colIndex}
                                            className="p-2"
                                            style={{ width: `${100 / comparedProducts.length}%` }}
                                        >
                                            <Skeleton className="w-full h-5 rounded-lg" />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div
                className={`transition-opacity duration-1000 ease-in-out ${markdownContent ? "opacity-100" : "opacity-0"
                    }`}
            >
                <JsonToTable data={markdownContent}></JsonToTable>
            </div>
        </>
    )
}


export default ProductComparisonTable;