import React, { useState, useEffect } from "react";
import JsonToTable from "./components/Table";
import dummySearchResults from "./dummySearchResults.json";
import example_product_table from "./example_product_table.json";
import Navbar from "./components/Navbar";
import ReactMarkdown from "react-markdown";

import { Skeleton } from "@nextui-org/skeleton";
import VideoGallery from "./components/VideoGallery";
import SearchBox from "./components/SearchBox";
import ProductSelection from "./components/ProductSelection";
import ProductComparisonTable from "./components/ProductComparisonTable";
import { v4 as uuidv4 } from 'uuid';

export enum SearchType {
  COMPARE_PRODUCTS,
  FIND_BEST_PRODUCT,
  FIND_ALTERNATIVES,
}

export interface ProductTableData {
  products: { name: string; image: string }[];
  rows: {
    feature: string;
    [productName: string]: string;
    implications: string;
  }[];
}

export interface VideoData {
  video_title: string;
  length: string;
  video_thumbnail: string;
  transcript: string;
  link: string;
}

export interface Product {
  name: string;
  description: string;
}

export class RequestCancelledError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RequestCancelledError';
  }
}

const MainPage = () => {
  const API_URL =
    process.env.REACT_APP_BACKEND_API_URL || "http://localhost:8000";

  const [searchInput, setSearchInput] = useState("");
  const [videoData, setVideoData] = useState<VideoData[]>(dummySearchResults);
  const [products, setProducts] = useState([]);
  const [markdownContent, setMarkdownContent] = useState<ProductTableData | null>(example_product_table);
  const [conclusion, setConclusion] = useState("");

  const [searchType, setSearchType] = useState(SearchType.COMPARE_PRODUCTS);

  const [comparedProducts, setComparedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVideos, setIsLoadingVideos] = useState(false)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)

  const [requestId, setRequestId] = useState<string | null>(null);


  const cancelRequest = async (requestId: string) => {
    try {
      const response = await fetch(`${API_URL}/api/cancel-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({ request_id: requestId }),
      });
      if (!response.ok) {
        throw new Error("Error when calling /cancel-request endpoint");
      }
      console.log("Request cancelled successfully")
    } catch (error) {
      console.error("Error cancelling request:", error);
    }
  }

  const fetchVideoData = async (searchQuery: string) => {

    const newRequestId = uuidv4()
    setRequestId(newRequestId)

    try {
      const response = await fetch(
        `${API_URL}/api/compare-products-videos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_query: searchQuery,
          request_id: newRequestId
        }),
      }
      );
      
      if (response.headers.get('X-Request-Cancelled') === 'true') {
        console.debug("Request was cancelled by the server and a new request was started");
        return;
      }

      if (!response.ok) {
        throw new Error("Error when calling /compare-products-videos endpoint");
      }

      const videoDataResponse = await response.json();
      return { video_data: videoDataResponse.video_data, products: videoDataResponse.products };
    } catch (error) {
      console.log(error)
      console.error("Error fetching video data:", error);
      setIsLoadingVideos(false)
    } finally {
      setRequestId(null)
    }
  }


  const fetchProductComparison = async (searchQuery: string, videoData: VideoData[]) => {
    try {
      const newRequestId = uuidv4()
      setRequestId(newRequestId)

      const response = await fetch(`${API_URL}/api/compare-products-table`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search_query: searchQuery,
          video_data: videoData,
          request_id: newRequestId,
        }),
      });

      console.log(response)
      if (response.headers.get('X-Request-Cancelled') === 'true') {
        console.debug("Request was cancelled by the server and a new request was started");
        return;
      }

      if (!response.ok) {
        throw new Error("Error when calling /compare-products-table endpoint");
      }

      const responseData: {
        product_table: ProductTableData;
        conclusion: string;
      } = await response.json();
      console.log(responseData.product_table);
      return {
        product_table: responseData.product_table,
        conclusion: responseData.conclusion
      }
    } catch (error) {
      console.error("Error fetching product table:", error);
    } finally {
      setRequestId(null)
    }
  }

  const compareProducts = async (searchQuery: string) => {
    try {
      setIsLoading(true);

      if (requestId) {
        await cancelRequest(requestId)
      }

      setMarkdownContent(null);
      setConclusion("");
      setVideoData([]);

      setIsLoadingVideos(true)
      setIsLoadingTable(false)
      if (searchType === SearchType.COMPARE_PRODUCTS) {
        setProducts([]);
        setSelectedProducts([]);
      }

      const videoDataResponse = await fetchVideoData(searchQuery)
      if (!videoDataResponse) {
        return;
      }
      const { video_data, products } = videoDataResponse
      setComparedProducts(products);
      setVideoData(video_data);

      setIsLoadingVideos(false);
      setIsLoadingTable(true)

      const productComparison = await fetchProductComparison(searchQuery, videoDataResponse.video_data)
      if (!productComparison) {
        return;
      }
      const { product_table, conclusion } = productComparison
      setMarkdownContent(product_table);
      setConclusion(conclusion);

      setIsLoadingVideos(false)
      setIsLoadingTable(false)
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoadingVideos(false)
      setIsLoadingTable(false)
    }
  };


  const fetchBestProduct = async (searchQuery: string) => {
    try {
      const newRequestId = uuidv4()
      setRequestId(newRequestId)

      const response = await fetch(`${API_URL}/api/find-products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search_query: searchQuery,
          request_id: newRequestId,
        }),
      });

      if (response.headers.get('X-Request-Cancelled') === 'true') {
        console.debug("Request was cancelled by the server and a new request was started");
        return;
      }

      if (!response.ok) {
        throw new Error("Error when calling /compare-products-table endpoint");
      }

      const products = await response.json();
      return products
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setRequestId(null)
    }
  }

  const findBestProduct = async (searchQuery: string) => {
    try {
      setIsLoading(true);

      if (requestId) {
        await cancelRequest(requestId)
      }

      setSelectedProducts([]);
      setProducts([]);
      setVideoData([]);
      setMarkdownContent(null);
      setConclusion("")

      setIsLoadingProducts(true)
      const products = await fetchBestProduct(searchQuery)
      if (!products) {
        return;
      }
      setProducts(products);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoadingProducts(false)
      setIsLoading(false);
    }
  };

  const handleButtonClick = async (customSearchInput: string | null = null) => {
    const queryToUse =
      customSearchInput !== null ? customSearchInput : searchInput;

    if (queryToUse === "") {
      return;
    }

    if (searchType === SearchType.COMPARE_PRODUCTS) {
      compareProducts(queryToUse);
    } else if (searchType === SearchType.FIND_BEST_PRODUCT) {
      findBestProduct(queryToUse);
    }
  };

  const handleProductButtonClick = (productName: string) => {
    setSelectedProducts((prev) => {
      if (prev.includes(productName)) {
        return prev.filter((name) => name !== productName);
      } else if (prev.length < 3) {
        return [...prev, productName];
      } else {
        return prev;
      }
    });
  };

  return (
    <div>
      <Navbar></Navbar>

      <div className="py-2 sm:py-10"></div>

      {/* Header Text */}
      <div className="font-bold text-center text-3xl sm:text-5xl max-w-3xl px-4 mx-auto">
        <h1>
          <span className="underline decoration-4 decoration-green-500/50">
            Pros
          </span>{" "}
          &{" "}
          <span className="underline decoration-4 decoration-red-500/50">
            Cons
          </span>{" "}
          for <span className="italic"> any </span>product based on{" "}
          <span className="underline decoration-4 decoration-blue-500/50">
            YouTube
          </span>{" "}
          reviews.
        </h1>
      </div>

      <div className="py-6 sm:py-10"></div>

      <SearchBox
        handleButtonClick={handleButtonClick}
        isLoading={isLoading}
        setSearchType={setSearchType}
        searchType={searchType}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      ></SearchBox>

      <ProductSelection
        products={products}
        selectedProducts={selectedProducts}
        handleProductButtonClick={handleProductButtonClick}
        compareProducts={compareProducts}
        isLoadingProducts={isLoadingProducts}
        isLoadingVideos={isLoadingVideos}
        isLoadingTable={isLoadingTable}
        isLoading={isLoading}
      />

      {/* spacing */}
      <div className="py-6"></div>

      <VideoGallery
        isLoadingVideos={isLoadingVideos}
        videoData={videoData}
      />

      <ProductComparisonTable
        isLoadingTable={isLoadingTable}
        comparedProducts={comparedProducts}
        markdownContent={markdownContent}
      />

      {/* space */}
      <div className="py-6"></div>

      {/* Conclusion */}
      {conclusion && (
        <div
          className={`max-w-3xl px-3 mx-auto transition-opacity duration-1000 ease-in-out ${conclusion ? "opacity-100" : "opacity-0" // TODO: use invisible / hidden instead of opacity
            }`}
        >
          <h1 className="text-2xl font-bold mb-2">
            Conclusion
          </h1>
          <div className="prose-sm sm:prose max-w-full leading-5">
            <ReactMarkdown
              components={{
                ul: ({ node, ...props }) => <ul className="list-disc list" {...props} />,
              }}
            >
              {conclusion}
            </ReactMarkdown>
          </div>
        </div>
      )}

    </div>
  );
};

export default MainPage;
