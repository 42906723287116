import React, { useEffect } from 'react';
import { ProductTableData } from '../MainPage';

const JsonToTable = ({ data }: { data: ProductTableData | null }) => {

    if(!data){
        return null
    }
    
    const products = data.products;
    const rows = data.rows;
    const headers = ["", ...products.map(product => product.name), ""];


    return (
        <div className="my-4 px-2 overflow-x-auto">
            <table className="w-full max-w-6xl mx-auto text-[0.5rem] sm:text-xs md:text-sm text-center">
                <thead className="text-gray-700 bg-gray-50">
                    <tr className="bg-white text-lg">
                        {headers.map((header, index) => (
                            <th key={index} className="px-1 py-2 sm:px-2 sm:py-3 md:px-4 md:py-4">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}>
                            {Object.entries(row).map(([key, value], cellIndex) => {
                                const isFirstColumn = key.toLowerCase() === 'feature';
                                const isLastColumn = key.toLowerCase() === 'implications';
                                const isPriceRow = row['Feature']?.toLowerCase() === 'price';
                                const shouldBeOrange = isPriceRow && !isFirstColumn && !isLastColumn;

                                return (
                                    <td 
                                        key={key} 
                                        className={`
                                            p-2 sm:p-3 md:p-4 whitespace-pre-line
                                            ${isFirstColumn ? 'font-bold break-words' : ''}
                                            ${isLastColumn ? 'text-left text-gray-500 italic' : ''}
                                            ${shouldBeOrange ? 'text-orange-500' : ''}
                                        `}
                                    >
                                        {typeof value === 'string' && (value.startsWith('data:image') || value.startsWith('http')) ? ( // TODO: Find a better way to check if value is an image, which might be Base64 or, in the future, a URL
                                        <img src={value} className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 object-contain rounded-md mx-auto"/>
                                        ) : (
                                        typeof value === 'string' ? value.replace(/<br\s*\/?>/gi, '\n') : value // Replace <br> with newline character for line breaks
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default JsonToTable;